.tour .SectionTabs-section-346 {
  padding: 50px 0;
  background: rgb(255, 255, 255);
}

button {
  background: transparent;
  border-color: transparent;
  padding: 140px 180px;
  top: 0px;
  right: 0px;
  position: absolute;
  z-index: 5;
}

img.slick-image {
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  object-fit: cover;
}

iframe.youtube_vid {
  width: 540px;
  height: 400px;
}

iframe.location_map {
  width: 350px;
  height: 300px;
}

img.themeImage {
  margin-top: 50px;
  border-radius: 5px;
  object-fit: cover;
  width: 500px;
  height: 400px;
}

img.notes {
  width: 450px;
  height: auto;
}
img.coins {
  width: 450px;
  height: auto;
}

@media (min-width: 960px) and (max-width: 1024px) {
  iframe.youtube_vid {
    width: 400px;
    height: 300px;
  }

  iframe.location_map {
    width: 200px;
    height: 180px;
  }

  img.themeImage {
    border-radius: 5px;
    object-fit: cover;
    width: 350px;
    height: 300px;
  }
  img.notes,
  img.coins {
    margin-left: 20px;
    width: 320px;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  iframe.youtube_vid {
    width: 650px;
    height: 400px;
  }

  iframe.location_map {
    width: 650px;
    height: 300px;
  }

  img.themeImage {
    margin-left: 50px;
    width: 570px;
    height: 300px;
  }

  img.notes,
  img.coins {
    margin-left: 60px;
    width: 500px;
    height: auto;
  }
}

@media (min-width: 426px) and (max-width: 586px) {
  iframe.youtube_vid {
    width: 350px;
    height: 300px;
  }

  img.themeImage {
    width: 330px;
    height: 250px;
  }

  img.notes,
  img.coins {
    width: 350px;
    height: auto;
  }
}

@media (max-width: 425px) {
  iframe.youtube_vid {
    width: 320px;
    height: 300px;
  }

  iframe.location_map {
    width: 320px;
    height: 300px;
  }

  img.themeImage {
    margin-left: 10px;
    width: 300px;
    height: 250px;
  }

  img.notes,
  img.coins {
    width: 300px;
    height: auto;
  }
  img.slick-image {
    height: 400px;
  }
}

@media (max-width: 375px) {
  iframe.youtube_vid {
    width: 270px;
    height: 200px;
  }

  iframe.location_map {
    width: 270px;
    height: 200px;
  }

  img.themeImage {
    margin-left: 10px;
    width: 250px;
    height: 180px;
  }

  img.notes,
  img.coins {
    margin-left: 30px;
    width: 200px;
    height: auto;
  }

  img.slick-image {
    height: 350px;
  }
}

@media (max-width: 320px) {
  h1,
  .h1 {
    font-size: 1.5rem;
  }

  iframe.youtube_vid {
    display: none;
  }

  iframe.location_map {
    display: none;
  }

  img.themeImage {
    margin-left: 10px;
    width: 200px;
    height: 150px;
  }

  img.notes,
  img.coins {
    width: 150px;
    height: auto;
  }

  img.slick-image {
    height: 350px;
  }
}
